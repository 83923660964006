.preloader_layer {
  background: #fff;
  position: fixed;
  z-index: 5000;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.preloader_block {
  width: 200px;
  -webkit-animation: preloader 1.8s infinite;
  animation: preloader 1.8s infinite;
}
.preloader_block svg {
  transform: rotate(180deg);
  width: 100%;
  height: 100%;
}
.preloader.hide {
  opacity: 0;
  pointer-events: none;
}
@-moz-keyframes preloader {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes preloader {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes preloader {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes preloader {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
