
.preloader{
	&_layer {
    background: #fff;
    position: fixed;
    z-index: 5000;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: opacity .3s ease;
    -moz-transition: opacity .3s ease;
    -ms-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: opacity .3s ease;

	}
	&_block {
	width: 200px;
    -webkit-animation: preloader 1.8s infinite;
    animation: preloader 1.8s infinite;
		svg {
			transform: rotate(180deg);
			width: 100%;
			height: 100%;

		}
	}
	&.hide {
		opacity: 0;
		pointer-events: none;
	}
}


@keyframes preloader {
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.6;
  }
  100%{
    opacity: 1;
  }
}
